<template>
    <div>
        <CRow>
            <CCol sm="12">
                <CCard>
                    <CCardHeader>
                        <strong>Update Trigger</strong>
                    </CCardHeader>
                    <CCardBody>
                        <CForm @keyup.enter="validator();submit()">
                            <CRow>
                                <CCol>
                                    <small>Use comma to add more fields, eg Phone Number, Telegram ID or E-mail.</small>
                                </CCol>
                            </CRow>
                            <br>
                            <CRow>
                                <CCol sm="3">
                                    <label>User</label>
                                        <model-select :options="users" v-model="form.user_id.val" placeholder="Please Select">
                                        <small class="form-text text-muted w-100">{{form.user_id.inv}}</small>
                                    </model-select>
                                </CCol>
                                <CCol sm="3">
                                    <CInput v-model="form.name.val" :description="form.name.inv" label="Name" />
                                </CCol>
                                <CCol sm="3">
                                    <label>Processes</label>
                                        <model-select :options="input_proccesses" v-model="form.input_proccess_id.val" placeholder="Please Select">
                                        <small class="form-text text-muted w-100">{{form.input_proccess_id.inv}}</small>
                                    </model-select>
                                </CCol>
                                <CCol sm="3">
                                    <CSelect
                                        v-model="form.is_active.val"
                                        :description="form.name.inv"
                                        label="State"
                                        :options="[
                                            {value: 'true', label:'Active'},
                                            {value: 'false', label:'Inactive'},
                                        ]"
                                        placeholder="Please Select"
                                        @change="form.is_active.val=$event.target.value"
                                    />
                                </CCol>
                            </CRow>
                            <CRow>
                                <CCol sm="3">
                                    <CInput v-model="form.compare_with.val" :description="form.compare_with.inv" label="Compare with" />
                                </CCol>
                                <CCol sm="3">
                                    <CSelect
                                        v-model="form.compare_type.val"
                                        :description="form.compare_type.inv"
                                        label="Compare Type"
                                        :options="[
                                            {value: '==', label:'=='},
                                            {value: '!=', label:'!='},
                                            {value: '>=', label:'>='},
                                            {value: '<=', label:'<='},
                                            {value: '<', label:'<'},
                                            {value: '>', label:'>'},
                                        ]"
                                        placeholder="Please Select"
                                        @change="form.compare_type.val=$event.target.value"
                                    />
                                </CCol>
                                <CCol sm="3">
                                    <CTextarea v-model="form.phone_numbers.val" :description="form.phone_numbers.inv" label="Phone Numbers" rows="1" />
                                </CCol>
                                <CCol sm="3">
                                    <CTextarea v-model="form.telegram_ids.val" :description="form.telegram_ids.inv" label="Telegram IDs" rows="1" />
                                </CCol>
                            </CRow>
                            <CRow>
                                <CCol sm="3">
                                    <CTextarea v-model="form.email_addresses.val" :description="form.email_addresses.inv" label="E-mails" rows="1" />
                                </CCol>
                                <CCol sm="3">
                                    <CTextarea v-model="form.callback_text.val" :description="form.callback_text.inv" label="Callback Text" rows="1" />
                                </CCol>
                                <CCol sm="3">
                                    <CTextarea v-model="form.detail.val" :description="form.detail.inv" label="Description" rows="1" />
                                </CCol>
                            </CRow>
                            <br>
                        </CForm>
                        <br>
                        <div class="form-group form-actions">
                            <CButton @click="validator();submit()" color="primary" class="mr-2"> Update</CButton>
                            <CButton color="dark" @click="go_back">Back</CButton>
                        </div>    
                    </CCardBody>
                </CCard>
            </CCol>
        </CRow>
    </div>
</template>

<script>
import { ModelSelect } from 'vue-search-select'
export default {
    components: { ModelSelect },
    data() {
        return {
            form: {
                user_id: {val: '', inv: '', v: { req: true, max: 1024 }},
                name: {val: '', inv: '', v: { req: true, min: 2, max: 1024 }},
                input_proccess_id: {val: '', inv: '', v: { req: true, max: 1024 }},
                is_active: {val: '', inv: '', v: { req: true, max: 1024 }},
                compare_with: {val: '', inv: '', v: { req: true, max: 1024 }},
                compare_type: {val: '', inv: '', v: { req: true, max: 1024 }},
                phone_numbers: {val: '', inv: '', v: { max: 1024 }},
                telegram_ids: {val: '', inv: '', v: { max: 1024 }},
                email_addresses: {val: '', inv: '', v: { max: 1024 }},
                callback_text: {val: '', inv: '', v: { req: true, min: 2, max: 1024 }},
                detail: {val: '', inv: '', v: { req: true, min: 2, max: 1024 }},
            },
            input_proccesses: [],
            users: [],
            disable: false,
        }
    },
    mounted(){
        this.get_users();
        this.get();
        this.get_input_proccesses();
    },
    methods: {
        validator() {
            for (const key in this.form) {
                this.form[key].inv = '';
            }
            this.disable = false;
            for (const key in this.form) {
                for (const k in this.form[key].v) {
                    switch (k) {
                        case 'req':
                            if (this.form[key].val == '') {
                                this.form[key].inv = 'This field is required.';
                                this.disable = true;
                            }
                            break;
                        case 'max':
                            if (this.form[key].val != '') {
                                if (this.form[key].val.length > this.form[key].v[k]) {
                                    this.form[key].inv = `Must be a maximum of ${this.form[key].v[k]} characters.`;
                                    this.disable = true;
                                }
                            }
                            break;
                        case 'min':
                            if (this.form[key].val != '') {
                                if (this.form[key].val.length < this.form[key].v[k]) {
                                    this.form[key].inv = `Must be at least ${this.form[key].v[k]} characters.`;
                                    this.disable = true;
                                }
                            }
                            break;
                    }
                }
            }
        },
        get_input_proccesses(){
            this.axios.get('arbitrage/input_proccesses/')
                .then(res => {
                    res.data.map(val => {
                        this.input_proccesses.push({value: val.id, text: val.name});
                    });
                })  
        },
        get_users(){
            this.axios.get('system/users/')
                .then(res => {
                    res.data.map(val => {
                        this.users.push({ value: val.id, text: `${val.id} - ${val.first_name} ${val.last_name}` });
                    });
                })
        },
        get(){
           this.axios.get('arbitrage/input_proccess_triggers/'
                + '?id=' + this.$route.params.id)
                .then(res => {
                    for(const key in res.data[0]){
                        for(const k in this.form){
                            if(key == k){
                                if(key == 'is_active'){
                                   this.form[k].val = `${res.data[0][key]}`; 
                                } else{
                                    this.form[k].val = res.data[0][key];
                                }
                            }
                        }
                    }
                    if(res.data[0].callback_info_data.email_addresses != null){
                        for (const i in res.data[0].callback_info_data.email_addresses){
                            this.form.email_addresses.val += res.data[0].callback_info_data.email_addresses[i];
                            if(i != (res.data[0].callback_info_data.email_addresses.length-1)){
                              this.form.email_addresses.val+= ',';  
                            }
                        }
                    }
                    if(res.data[0].callback_info_data.telegram_ids != null){
                        for (const i in res.data[0].callback_info_data.telegram_ids){
                            this.form.telegram_ids.val += res.data[0].callback_info_data.telegram_ids[i];
                            if(i != (res.data[0].callback_info_data.telegram_ids.length-1)){
                              this.form.telegram_ids.val+= ',';  
                            }
                        }
                    }
                    if(res.data[0].callback_info_data.phone_numbers != null){
                        for (const i in res.data[0].callback_info_data.phone_numbers){
                            this.form.phone_numbers.val += res.data[0].callback_info_data.phone_numbers[i];
                            if(i != (res.data[0].callback_info_data.phone_numbers.length-1)){
                              this.form.phone_numbers.val+= ',';  
                            }
                        }
                    }
                    if(res.data[0].callback_info_data.callback_text != null){
                        this.form.callback_text.val = res.data[0].callback_info_data.callback_text;
                    }
                }) 
        },
        submit(){
            if(this.disable == false){
                const data = new FormData();
                data.set('ids', JSON.stringify([parseInt(this.$route.params.id)]));
                for (const key in this.form){
                    if(key == 'phone_numbers' || key == 'email_addresses'){
                        data.set(key, JSON.stringify(this.form[key].val.split(',')));
                    } else if(key == 'telegram_ids'){
                        let i = this.form[key].val.split(',');
                        let o = [];
                        for (const x in i){
                            o.push(parseInt(i[x]))
                        }
                        data.set(key, JSON.stringify(o));
                    } else if(key == 'compare_with'){
                        data.set(key,parseFloat(this.form[key].val));
                    } else {
                        data.set(key, this.form[key].val);
                    }
                }
                this.axios.put('arbitrage/input_proccess_triggers/', data)
                    .then(() => {
                        this.$notify({
                            group: 'foo',
                            type: 'success',
                            text: 'Updated Successfully.'
                        });
                        this.$router.push('/triggers');
                    })
                    .catch(() => {
                        this.$notify({
                            group: 'foo',
                            type: 'error',
                            text: 'Error! please try again.'
                        });
                    })
            }
        },
        go_back(){
            this.$router.go(-1);
        }
    }
}
</script>
